<template>
  <div class="wrap">
    <div class="resultbox" v-for="item in endgames" v-if="gametype == 1">
      <div class="teams time">
        {{ item.sequence }}
      </div>
      <div class="teams home">
        <div style="width: 70%">{{ item.homeTeamName }}</div>
        <div style="padding-right: 3px;">{{ item.homeTeamOdds }}</div>
      </div>
      <div class="teams draw" style="color: #ffa500">{{ item.homeTeamScore }} : {{ item.awayTeamScore }}</div>
      <div class="teams away">
        <div style="padding-left: 3px;">{{ item.awayTeamOdds }}</div>
        <div style="width: 70%">{{ item.awayTeamName }}</div>
      </div>
    </div>
    <div class="resultbox" v-if="gametype == 2" style="flex-wrap: wrap;">
      <div style="width: 33%;border: 1px solid #424141;line-height: 28px;margin-bottom: 3px" v-for="item in endgames">
        {{ item.sequence }} <span style="color: #ffa500">우승번호:{{ item.gameResultString }}번</span></div>
    </div>

  </div>

</template>

<script>
import {getBet365SportsReulst} from "../../network/leisureRequest";

export default {
  name: "LeisureBet365GameRsultComp",
  props: {
    kind: {
      type: Number,
      default() {
        return 0
      },
    },
    gametype: {
      type: Number,
      default() {
        return 1
      },
    }
  },
  data() {
    return {
      endgames: [],
    }
  },
  computed: {},
  methods: {
    getResult() {
      getBet365SportsReulst(this.kind, this.gametype).then(res => {
        this.endgames = res.data.data
      })
    },
  },
  created() {
    this.getResult()
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  position: absolute;
  top: 380px;
  display: none;
}

.resultbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 3px;
  box-sizing: border-box;
  padding: 0 40px;
}

.teams {
  height: 28px;
  line-height: 28px;
  border: 1px solid #4b4b4b;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.time {
  width: 12%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
}

.home {
  width: 39%;
  text-align: left;
  padding-left: 3px;
}

.draw {
  width: 10%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;

}

.away {
  width: 39%;
  text-align: right;
  padding-right: 3px;
}

@media screen and (max-width: 1024px) {
  .wrap {
    top: 210px;
    padding: 0 3px;
    display: block;
  }

  .resultbox {
    padding: 0 !important;
  }

  .time {
    width: 15% !important;
  }

  .home {
    width: 35% !important;
  }

  .away {
    width: 35% !important;
  }
}
</style>